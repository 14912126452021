<script>
import CrudFieldSelectorChest from '@/components/forms/crud-fields/CrudFieldSelectorChest.vue'
import CrudFieldSelectorRushEvent from '@/components/forms/crud-fields/CrudFieldSelectorRushEvent.vue'
import CrudFieldSelectorQuestGroup from '@/components/forms/crud-fields/CrudFieldSelectorQuestGroup.vue'
import CrudFieldSelectorCardEvent from '@/components/forms/crud-fields/CrudFieldSelectorCardEvent.vue'
import CrudFieldSelectorCardExchangeEvent from '@/components/forms/crud-fields/CrudFieldSelectorCardExchangeEvent.vue'

export default {
  components: {
    CrudFieldSelectorCardEvent,
    CrudFieldSelectorQuestGroup,
    CrudFieldSelectorRushEvent,
    CrudFieldSelectorChest,
    CrudFieldSelectorCardExchangeEvent
  }
}
</script>

<template>
  <crud-details-page
    api="season"
    disable-top-margin
  >
    <template #form>
      <!-- No -->
      <crud-field-number
        :sm="8"
        api-field-name="No"
        required
      />
      <!-- Name -->
      <crud-field-text
        :sm="8"
        api-field-name="Name"
        required
      />
      <!-- NameTag -->
      <crud-field-selector-client-strings
        :sm="8"
        api-field-name="NameTag"
      />
      <section-divider>Settings</section-divider>
      <fields-col :sm="8">
        <!-- SeasonBackground -->
        <crud-field-selector-files api-field-name="SeasonBackground" />
        <!-- BattleLocationBackground -->
        <crud-field-selector-files
          api-field-name="BattleLocationBackground"
          label="Battle location"
        />
        <!-- RewardBackground -->
        <crud-field-selector-files
          api-field-name="RewardBackground"
          label="Season pass banner"
        />
        <!-- HomeBackground -->
        <crud-field-selector-files api-field-name="HomeBackground" />
        <!-- SeasonPassBackground -->
        <crud-field-selector-files api-field-name="SeasonPassBackground" />
      </fields-col>
      <fields-col :sm="8">
        <!-- PreBattleBackground -->
        <crud-field-selector-files api-field-name="PreBattleBackground" />
        <!-- SeasonStartPopupBackground -->
        <crud-field-selector-files api-field-name="SeasonStartPopupBackground" />
        <!-- SeasonStartPopupCustomTitle -->
        <crud-field-selector-files api-field-name="SeasonStartPopupCustomTitle" />
        <!-- GuildTrophyFile -->
        <crud-field-selector-files api-field-name="GuildTrophyFile" />
        <!-- PlayerTrophyFile -->
        <crud-field-selector-files api-field-name="PlayerTrophyFile" />
      </fields-col>
      <fields-col :sm="8">
        <!-- SeasonCalendar -->
        <crud-field-select
          api-field-name="SeasonCalendar"
          options-enum="enums.SeasonCalendar"
        />
        <crud-field-selector-files api-field-name="CompetitionsScreenBackground" />
        <crud-field-selector-files api-field-name="CompetitionsLeagueBanner" />
      </fields-col>

      <section-divider>Rush Events</section-divider>
      <fields-col :sm="12">
        <template
          v-for="i in 8"
          :key="i"
        >
          <CrudFieldSelectorRushEvent
            :sm="16"
            :api-field-name="`RushEvent${i}`"
            :label="`Rush Event ${i}`"
          />
          <crud-field-number
            :sm="8"
            :api-field-name="`RushEvent${i}StartSeasonDay`"
            :label="`Start Season Day ${i}`"
            :max="255"
          />
        </template>
      </fields-col>

      <section-divider>Chests</section-divider>
      <template
        v-for="i in 6"
        :key="i"
      >
        <CrudFieldSelectorChest
          :sm="8"
          :api-field-name="`Chest${i}`"
        />
        <crud-field-selector-item
          :sm="8"
          :api-field-name="`Chest${i}Key`"
        />
        <crud-field-number
          :sm="4"
          :api-field-name="`Chest${i}SeasonDayStart`"
          :label="`SeasonDayStart${i}`"
        />
        <crud-field-number
          :sm="4"
          :api-field-name="`Chest${i}DurationDays`"
          :label="`DurationDays${i}`"
        />
      </template>

      <section-divider>Quest Groups</section-divider>
      <template
        v-for="i in 10"
        :key="i"
      >
        <CrudFieldSelectorQuestGroup
          :sm="8"
          :api-field-name="`QuestGroup${i}`"
        />
        <crud-field-number
          :sm="4"
          :api-field-name="`QuestGroup${i}SeasonDayStart`"
          :label="`Season day start ${i}`"
        />
        <crud-field-selector-client-strings
          :sm="6"
          :api-field-name="`QuestGroup${i}StartNotification`"
          :label="`Group ${i} Start Notification`"
        />
        <crud-field-selector-client-strings
          :sm="6"
          :api-field-name="`QuestGroup${i}EndNotification`"
          :label="`Group ${i} End Notification`"
        />
      </template>

      <section-divider>Card Events</section-divider>
      <template
        v-for="i in 3"
        :key="i"
      >
        <fields-col :sm="14">
          <CrudFieldSelectorCardEvent
            :sm="16"
            :api-field-name="`CardEvent${i}`"
          />
          <crud-field-number
            :sm="8"
            :api-field-name="`CardEvent${i}SeasonDayStart`"
            :label="`Season day start ${i}`"
          />
        </fields-col>
      </template>

      <section-divider>Card Exchange Events</section-divider>
      <template
        v-for="i in 7"
        :key="i"
      >
        <CrudFieldSelectorCardExchangeEvent
          :sm="8"
          :api-field-name="`CardExchangeEvent${i}`"
        />
        <crud-field-number
          :sm="4"
          :api-field-name="`CardExchangeEvent${i}SeasonDayStart`"
          :label="`Season day start ${i}`"
        />
        <crud-field-number
          :sm="7"
          :api-field-name="`CardExchangeEvent${i}SeasonDayHour`"
          :label="`Season day hour ${i}`"
          :vertical="$windowWidth < 640"
        >
          <template #fieldAppend>
            <div class="pl-1 pt-1 text-green-600">
              EUROPE ZONE TIME
            </div>
          </template>
        </crud-field-number>
      </template>

      <section-divider>Lottery Events</section-divider>
      <fields-col :sm="14">
        <template
          v-for="i in 3"
          :key="i"
        >
          <CrudFieldSelectorLotteryEvent
            :sm="16"
            :api-field-name="`LotteryEvent${i}`"
          />
          <crud-field-number
            :sm="8"
            :api-field-name="`LotteryEvent${i}SeasonDayStart`"
          />
        </template>
      </fields-col>

      <section-divider>Transfer Windows</section-divider>
      <fields-col :sm="24">
        <crud-field-switcher
          :sm="8"
          api-field-name="TransferWindowEnabled"
          class="flex-none"
        />
      </fields-col>
      <template
        v-for="i in 2"
        :key="i"
      >
        <fields-col :sm="12">
          <crud-field-number
            :sm="12"
            :api-field-name="`TransferWindow${i}.StartSeasonDay`"
            :label="`StartSeasonDay ${i}`"
          />
          <crud-field-number
            :sm="12"
            :api-field-name="`TransferWindow${i}.EndSeasonDay`"
            :label="`EndSeasonDay ${i}`"
          />
          <crud-field-selector-files
            :sm="12"
            :api-field-name="`TransferWindow${i}.HomeBanner`"
            :label="`HomeBanner ${i}`"
          />
          <crud-field-selector-files
            :sm="12"
            :api-field-name="`TransferWindow${i}.InfoPopup`"
            :label="`InfoPopup ${i}`"
          />
        </fields-col>
      </template>
      <fields-col :sm="24">
        <crud-field-number
          :sm="12"
          api-field-name="TransferWindowDayNextSeason"
          class="flex-none"
          label="First transfer window of the next season day"
        />
      </fields-col>

      <section-divider>Tournaments</section-divider>
      <fields-col :sm="24">
        <template
          v-for="i in 3"
          :key="i"
        >
          <crud-field-selector-tournament
            :sm="10"
            :api-field-name="`Tournament${i}`"
          />
          <crud-field-number
            :sm="8"
            :api-field-name="`Tournament${i}StartSeasonDay`"
          />
        </template>
      </fields-col>
    </template>
  </crud-details-page>
</template>
