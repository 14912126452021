<script setup>
</script>

<template>
  <crud-details-page
    api="rewards/arithmetic-run-reward"
    disable-top-margin
  >
    <template #form>
      <fields-col :sm="6">
        <!-- Label -->
        <crud-field-text
          api-field-name="Label"
          required
        />
        <!-- Stage -->
        <crud-field-number
          api-field-name="Stage"
          required
        />
      </fields-col>
      <fields-col :sm="6">
        <!-- XpPct -->
        <crud-field-number api-field-name="XpPct" />
        <!-- Energy -->
        <crud-field-number api-field-name="Energy" />
        <!-- Gems -->
        <crud-field-number api-field-name="Gems" />
      </fields-col>
      <fields-col :sm="12">
        <template
          v-for="i in 4"
          :key="i"
        >
          <crud-field-selector-item
            :sm="12"
            :api-field-name="`Item${i}`"
          />
          <crud-field-number
            :sm="12"
            :api-field-name="`Item${i}Amount`"
          />
        </template>
      </fields-col>
    </template>
  </crud-details-page>
</template>
